import styled from 'styled-components';

export const Svg = styled.svg`
  height: 1.5em;
  fill: ${props => props.theme.colors.white};
  transition: fill 0.2s;

  &:hover {
    fill: ${props => props.theme.colors['bombay']};
  }
`;
