import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';

import Text from '../atoms/Text/Text';

type AppNameProps = {
  name: string;
};

class AppName extends PureComponent<AppNameProps> {
  render() {
    return (
      <Box smMb={2.625} mb={2.375}>
        <Text smSize={1.5} size={1.875} color="white" weight={300}>
          <span
            dangerouslySetInnerHTML={{
              __html: this.props.name,
            }}
          />
        </Text>
      </Box>
    );
  }
}

export default AppName;
