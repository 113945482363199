import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Link from '../atoms/Link/Link';
import Loader from '../atoms/Loader/Loader';
import { Row } from '../atoms/Grid';
import Text from '../atoms/Text/Text';
import Question from '../molecules/Question';

import { ROUTE } from '@utils/index';
import { getUrl } from '@utils/origin';

import { Align } from '@shared/enums/align';
import { I18nTranslation } from '@shared/interfaces/i18n';
import Observer from '@hoc/Observer';
import { Target } from '@shared/enums/target';
import { Trans, withTranslation } from 'i18n';

class Faq extends PureComponent<{ t: I18nTranslation }> {
  state = {
    loading: true,
  };

  t = key => this.props.t(`faq.${key}`);

  handleIntersectionCallback = (entry, observer) => {
    const target = entry[0];
    if (target.isIntersecting) {
      this.setState({
        loading: false,
      });
      observer.unobserve(target.target);
    }
  };

  render() {
    const qt = key => this.t(`questions.${key}`);

    const loader = <Loader />;
    const content = (
      <Box maxWidth={71}>
        <Box
          mr={1.375}
          ml={1.375}
          lgMr={0}
          lgMl={0}
          pt={3.5}
          lgPt={5.5}
          pb={3.5}
        >
          <Box align={Align.Center} mb={3.5} lgMb={4.125}>
            <Text
              color="cornflowerBlue"
              lineHeight="normal"
              smSize={1.25}
              size={2}
              weight="bold"
            >
              {this.t('title')}
            </Text>
          </Box>

          <Row gutter={1.375}>
            <Question title={qt('allDevices.question')}>
              {qt('allDevices.answer')}
            </Question>
            <Question title={qt('autoRenew.question')}>
              <Trans
                i18nKey="autoRenew.answer"
                components={{
                  lnk: <Link href={ROUTE.ACCOUNT.INDEX} color="robinEggBlue" />,
                }}
                t={qt}
              />
            </Question>
            <Question title={qt('dontRenew.question')}>
              {qt('dontRenew.answer')}
            </Question>
            <Question title={qt('enterprise.question')}>
              {qt('enterprise.answer')}
              <ExternalLink
                href={getUrl('https://3d4medical.com/institutional')}
                target={Target.Blank}
                color="robinEggBlue"
              >
                {qt('enterprise.link')}
              </ExternalLink>
              .
            </Question>
          </Row>
        </Box>
      </Box>
    );

    return (
      <Observer onChange={this.handleIntersectionCallback}>
        {this.state.loading ? loader : content}
      </Observer>
    );
  }
}

export default withTranslation('common')(Faq);
