import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import Text from '../atoms/Text/Text';

import AnalyticsManager from '@utils/analyticsManager';

import { Align } from '@shared/enums/align';
import { Color } from '@shared/enums/button-color';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Size } from '@shared/enums/button-size';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

type BannerTryProps = {
  onClickTrial: () => void;
  t: I18nTranslation;
};

class BannerTry extends PureComponent<BannerTryProps> {
  t = key => this.props.t(`banner.${key}`);

  handleClick = () => {
    AnalyticsManager.shared.pricingPage.addTiffTFT();
    AnalyticsManager.googleLegacy.pricingPage.addTiffTFT();
    this.props.onClickTrial();
  };

  render() {
    return (
      <Box
        maxWidth={43.125}
        smMl={1.5}
        smMr={1.5}
        smPt={4.375}
        smPb={3.125}
        pt={7.125}
        pb={4}
      >
        <Box align={Align.Center} mb={1.5}>
          <Text
            smSize={2}
            size={2.5}
            weight="bold"
            color="black"
            lineHeight="normal"
          >
            {this.t('trial')}
          </Text>
        </Box>
        <Box align={Align.Center} smMb={2} mb={4.5}>
          <Text smSize={1.25} size={1.5} color="black" lineHeight="normal">
            {this.t('3day')}
          </Text>
        </Box>
        <Box maxWidth={20}>
          <ButtonBannerTry
            onClick={this.handleClick}
            colorType={Color.FullGreen}
            sizeType={Size.FullWidthDefault}
          >
            {this.t('try')}
          </ButtonBannerTry>
        </Box>
      </Box>
    );
  }
}

export default withTranslation('common')(BannerTry);

const ButtonStyles = css`
  text-transform: uppercase;
  line-height: normal;
  font-weight: bold;
`;

const ButtonBannerTry = styled(Button)`
  padding-top: 1.625rem;
  padding-bottom: 1.625rem;
  font-size: 1.125em;
  ${ButtonStyles};

  ${breakpoint.m`
    font-size: 1.75em;
  `}
`;
