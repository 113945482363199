import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import { BilibiliIcon } from '../atoms/Socials/BilibiliIcon';
import Button from '../atoms/Button/Button';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import { FacebookIcon } from '../atoms/Socials/FacebookIcon';
import { InstagramIcon } from '../atoms/Socials/InstagramIcon';
import Loader from '../atoms/Loader/Loader';
import { MiniProgramIcon } from '../atoms/Socials/MiniProgramIcon';
import { RedbookIcon } from '../atoms/Socials/RedbookIcon';
import { Svg } from '../atoms/Socials/Svg';
import { TwitterIcon } from '../atoms/Socials/TwitterIcon';
import { WeChatIcon } from '../atoms/Socials/WeChatIcon';
import { YoutubeIcon } from '../atoms/Socials/YoutubeIcon';
import WeChatQRCodePopup from '@components/organisms/WeChatQRCodePopup';

import { I18nTranslation } from '@shared/interfaces/i18n';
import { MEDIA_POINT } from 'theme';
import { Target } from '@shared/enums/target';
import { withTranslation } from 'i18n';

const ExternalLinkExtend = styled(ExternalLink)`
  padding: 0.75em 0;
  display: inline-block;
  width: 45%;
  max-width: 3.125em;
  color: inherit;
  transition: color 0.2s;

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const WeChatButton = styled(Button)`
  padding: 0.75em 0;
  display: inline-block;
  width: 45%;
  max-width: 3.125em;
  color: inherit;
  transition: color 0.2s;
  &:hover {
    color: ${props => props.theme.colors.white};
  }
  @media screen and (min-width: ${MEDIA_POINT.M}px) {
    text-align: start;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.transparentColors.white60};
  padding: 0.75em 0;
  line-height: 1.5;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -5px;
`;
const StyledLoader = styled(Loader)`
  margin: 0.75em;
`;

type Network = {
  id: number;
  Svg: React.FC<{}>;
  href: string;
  isWeChat?: boolean;
};

type SocialNetworksProps = {
  className?: string;
  isMobile?: boolean;
  isLoadingCountry: boolean;
  isChinaLocation: boolean;
  t: I18nTranslation;
};

class SocialNetworks extends PureComponent<SocialNetworksProps> {
  state = {
    isOpenPopup: false,
  };

  networks: Network[] = [
    {
      id: 1,
      Svg: TwitterIcon,
      href: 'https://twitter.com/3d4medical',
    },
    {
      id: 2,
      Svg: FacebookIcon,
      href: 'https://facebook.com/3d4medical',
    },
    {
      id: 3,
      Svg: InstagramIcon,
      href: 'https://instagram.com/3d4medical',
    },
    {
      id: 4,
      Svg: YoutubeIcon,
      href: 'https://youtube.com/user/3d4medical',
    },
  ];

  chinaNetworks: Network[] = [
    {
      id: 1,
      Svg: WeChatIcon,
      href: '',
      isWeChat: true,
    },
    {
      id: 2,
      Svg: BilibiliIcon,
      href: 'https://space.bilibili.com/37923876?from=search&seid=12692546011419727017',
    },
    {
      id: 3,
      Svg: RedbookIcon,
      href: 'https://www.xiaohongshu.com/user/profile/5eb3b460000000000100735d?xhsshare=CopyLink&appuid=5eb3b460000000000100735d&apptime=1610364563',
    },
    {
      id: 4,
      Svg: MiniProgramIcon,
      href: 'https://erzkh.duanshu.com/#/brief/column/d487623104fa4fcc906cf22ad6709d7a/',
    },
  ];

  onOpenPopup = () => {
    this.setState({
      isOpenPopup: true,
    });
  };

  onClosePopup = () => {
    this.setState({
      isOpenPopup: false,
    });
  };

  render() {
    const { isMobile, className, t } = this.props;
    const currentNetworks = this.props.isChinaLocation
      ? this.chinaNetworks
      : this.networks;

    return (
      <div className={className}>
        <Title>{t('followUs')}</Title>
        <Wrapper>
          {this.props.isLoadingCountry ? (
            <StyledLoader />
          ) : (
            currentNetworks.map(network => {
              const { Svg } = network;

              return network.isWeChat ? (
                <WeChatButton key={network.id} onClick={this.onOpenPopup}>
                  <Svg />
                </WeChatButton>
              ) : (
                <ExternalLinkExtend
                  key={network.id}
                  href={network.href}
                  target={Target.Blank}
                >
                  <Svg />
                </ExternalLinkExtend>
              );
            })
          )}
        </Wrapper>
        {this.state.isOpenPopup && (
          <WeChatQRCodePopup onClose={this.onClosePopup} />
        )}
      </div>
    );
  }
}

const styledSocialNetworks = styled(withTranslation('common')(SocialNetworks))`
  ${({ isMobile, theme }) =>
    isMobile &&
    css`
      > ${Title} {
        font-size: 1.125em;
        color: ${theme.colors.white};
      }

      ${Svg} {
        height: 2.125em;
      }

      ${Wrapper} {
        text-align: center;
        flex-wrap: nowrap;
      }

      ${ExternalLinkExtend} {
        width: 100%;
        max-width: none;
      }
      ${WeChatButton} {
        width: 100%;
        max-width: none;
      }
    `}
`;

export default styledSocialNetworks;
