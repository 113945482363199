import React, { FC } from 'react';

import { Svg } from '@components/atoms/Socials/Svg';

export const FacebookIcon: FC = () => {
  return (
    <Svg viewBox="0 0 48 48">
      <g>
        <path
          id="White_2_"
          d="M45.4,0H2.6C1.2,0,0,1.2,0,2.6v42.7C0,46.8,1.2,48,2.6,48h23V29.4h-6.3v-7.2h6.3v-5.3c0-6.2,3.8-9.6,9.3-9.6c2.6,0,4.9,0.2,5.6,0.3V14l-3.8,0c-3,0-3.6,1.4-3.6,3.5v4.6h7.2l-0.9,7.2h-6.2V48h12.2c1.5,0,2.6-1.2,2.6-2.6V2.6C48,1.2,46.8,0,45.4,0z"
        />
      </g>
    </Svg>
  );
};
