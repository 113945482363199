import React from 'react';

import { connect } from 'react-redux';

import { AppDispatch } from 'redux-types';
import { AppState } from '@redux/types';
import { ApplicationPrices } from '@redux/reducers/applications/types';
import { QueryState } from '@redux/reducers/queryParams/types';
import { SalesState } from '@redux/reducers/sales/types';
import { _getCaApp, _getPrices } from '@redux/reducers/applications/selectors';
import { _getQueryParams } from '@redux/reducers/queryParams/selectors';
import { _getSales } from '@redux/reducers/sales/selectors';
import { login } from '@redux/reducers/auth/action';

import ChangePurchasedSubscriptionPopup from '@components/organisms/ChangePurchasedSubscriptionPopup';
import Layout from '@components/organisms/Layout';
import PricingTemplate from '@components/templates/PricingTemplate';

import { getUrl } from '@utils/origin';
import {
  NextFCWithInitialProps,
  getInitialPropsWithTranslation,
} from '@lib/getInitialProps';
import { Product } from '@shared/interfaces/product';
import { Sale } from '@shared/interfaces/sale';
import { TRIAL_SETUP_LINK } from '@shared/markup/constants';

type PricingProps = {
  application: Product;
  dispatch: AppDispatch;
  sale: Sale | SalesState;
  hasCurrentSale: boolean;
  queryParams: QueryState;
  prices: ApplicationPrices[];
  hasCompleteHeart: boolean;
};

const Pricing: NextFCWithInitialProps<PricingProps> = ({
  application,
  queryParams,
  sale,
  hasCurrentSale,
  prices,
  hasCompleteHeart,
  dispatch,
}) => {
  const handleLogin = () => {
    dispatch(login(false)).then(() => {
      window.location.href = getUrl(TRIAL_SETUP_LINK);
    });
  };

  return (
    <Layout>
      <PricingTemplate
        application={application}
        onLogin={handleLogin}
        sale={sale}
        hasCurrentSale={hasCurrentSale}
        queryParams={queryParams}
        prices={prices}
        hasCompleteHeart={hasCompleteHeart}
      />

      <ChangePurchasedSubscriptionPopup />
    </Layout>
  );
};

Pricing.getInitialProps = getInitialPropsWithTranslation(['common']);

const withConnect = connect((state: AppState) => {
  const application = _getCaApp(state);
  const sale = _getSales(state, application.id);
  const hasCurrentSale = Object.keys(sale).length !== 0;
  const queryParams = _getQueryParams(state);
  const hasCompleteHeart = queryParams.app === 'CompleteHeart';

  return {
    sale,
    hasCurrentSale,
    hasCompleteHeart,
    application,
    queryParams,
    prices: _getPrices(state),
  };
});

export default withConnect(Pricing);
