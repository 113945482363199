import React, { FC } from 'react';

import { Svg } from './Svg';

export const TwitterIcon: FC = () => {
  return (
    <Svg viewBox="0 0 48 48">
      <g id="Logo__x2014__FIXED">
        <g>
          <path d="M15.1,43.5c18.1,0,28-15,28-28c0-0.4,0-0.8,0-1.3c1.9-1.4,3.6-3.1,4.9-5.1c-1.8,0.8-3.7,1.3-5.7,1.6c2-1.2,3.6-3.2,4.3-5.5c-1.9,1.1-4,1.9-6.3,2.4c-1.8-1.9-4.4-3.1-7.2-3.1c-5.4,0-9.9,4.4-9.9,9.9c0,0.8,0.1,1.5,0.2,2.2C15.4,16.2,8.2,12.2,3.3,6.3C2.5,7.8,2,9.4,2,11.2c0,3.4,1.7,6.4,4.4,8.2c-1.6-0.1-3.1-0.5-4.5-1.2c0,0,0,0.1,0,0.1c0,4.8,3.4,8.8,7.9,9.7c-0.8,0.2-1.7,0.3-2.6,0.3c-0.6,0-1.2-0.1-1.8-0.2c1.2,3.9,4.9,6.8,9.2,6.8c-3.4,2.7-7.6,4.2-12.2,4.2c-0.8,0-1.6,0-2.3-0.1C4.3,41.9,9.5,43.5,15.1,43.5" />
        </g>
      </g>
    </Svg>
  );
};
